import * as React from 'react';
import { View, StyleSheet, Button, Dimensions, Text } from 'react-native';
import { Video, ResizeMode } from 'expo-av';
import { useFonts } from 'expo-font';
import  { useState, useEffect, useRef, useContext } from 'react'

export default function ShowsHeader() {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const [wwidth, setWwidth] = useState(window.innerWidth);
  const [hheight, setHheight] = useState(window.innerHeight);


  function handleWindowSizeChange() {
      setWwidth( Dimensions.get('window').width);
      setHheight(Dimensions.get('window').height);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (
    <View style={{flex: 1}}>
      <Video
        ref={video}
        videoStyle={{ width: wwidth, height:  hheight-100, minWidth: 500}}
        style={{ width: wwidth, height:  hheight-300, }}
        resizeMode={wwidth < 1000 ? 'cover' : 'stretch'}
        source={require('../assets/bandImages/betterWave.mp4')}
        shouldPlay
        isLooping
        onPlaybackStatusUpdate={status => setStatus(() => status)}
      />

    </View>
  );
}