import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable, ScrollView, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { Audio } from 'expo-av';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { FlatList, TouchableWithoutFeedback, TouchableHighlight } from 'react-native-web';
import SongBubble from './SongBubble';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function PlayButtonAndMore(props){
  const {isPlaying} = props;
  const [highlighted, setHighlighted] = useState(1)
  const [currentSongName, setCurrentSongName] = useState('Cold Hearted')
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
    const [fontsLoaded] = useFonts({
      'CG-Times Bold': require('../assets/cgtr65w.otf'),
      'CG-Times': require('../assets/CG Times Regular font.otf'),
    });

    useEffect(() => {
      window.addEventListener('resize', changeTitleSize);
      return () => {
          window.removeEventListener('resize', changeTitleSize);
      }
    }, []);
  
    function changeTitleSize(){
      setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
      setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    }


  const againStream = '19,964', coldHeartedStream = '492,097', culDeSacStream = '10,247', lilyStream = '19,866', detourStream = '11,951'
  const againTotalDuration = 13, coldHeartedDuration = 9, culDeSacDuration = 12, lilyDuration = 7, detourDuration = 6
  const first = '1)', second = '2)', third = '3)', fourth = '4)', fifth = '5)'
  return(
    <View>
      <View style={{position: 'absolute', justifyContent: 'center', alignItems: 'center',}}>
        <AnimatedCircularProgress
        rotation={0}
         size={35}
         width={3}
         fill={props.progress}
         tintColor="black"
        //  onAnimationComplete={() => //console.log('onAnimationComplete')}
         backgroundColor="transparent" />

        <TouchableOpacity onPress={() => {props.onPress()}} style={{ position: 'absolute',}}> 
          <MaterialCommunityIcons
            name={isPlaying ? "pause" : "play"}
            size={isPlaying ? 30 : 40}
            color="black"
          />
        </TouchableOpacity> 
      </View>
    </View>
  )
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: 'white'
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 55,
    padding: 10,
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 16,
    fontFamily: 'CG-Times', 
    marginLeft: 10
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
