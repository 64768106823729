import  { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { StyleSheet, Text, View, FlatList, ImageBackground, Image, TouchableOpacity, Platform, Alert, Linking} from 'react-native';
import { useFonts } from 'expo-font';
import { child, ref, set, get, getDatabase, onValue,} from "firebase/database";
import { db } from '../config';
import { LinearGradient } from 'expo-linear-gradient';
import TwoShows from './TwoShows';

export default function EventList(props) {
  const [firstRender, setFirstRender] = useState(true)
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
  const [events, setEvents] = useState([])
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const [FSS, setFSS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [usedImages, setUsedImages] = useState([])
  const [images, setImages] = useState([])
  const [currentImages, setCurrentImages] = useState([])
  const imageArray = [
    require('../assets/bandImages/4some.jpeg'),
    require('../assets/bandImages/Horizontal/H3.png'),
    require('../assets/bandImages/Horizontal/H1.png'),
    require('../assets/bandImages/Horizontal/H5.png'),
    require('../assets/bandImages/Horizontal/H4.png'),
  ]

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []); 
  
  useEffect(() => {
    readData()
  },[])

  // useEffect(() => {
  //   console.log("current images has changed", currentImages)
  //   if(currentImages.length === 1){
  //     setCurrentImages(images)
  //   }
  // }, [currentImages]); 

  useEffect(() => {
    //console.log("these are the events", events)
  }, [events]); 

  useEffect(() => {
    setFirstRender(false)
    if(firstRender) return
    var tempArr = []
    for(let i = 0; i < events?.length; i++){
      let uri = grabRandomURI()
      tempArr[i] = uri
    }
    console.log("this is the images we setting", tempArr)
    setImages(tempArr)
    setCurrentImages(tempArr)
  },[events])

  function readData(){
    const showsRef = ref(db, 'shows');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      //console.log("this the data", data)
      setEvents(data);
    });
  }

  function toWebsite(url){
    console.log("this is the url", url)
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: ", url);
      }
    });
  }

  function toDirections(lat, long){
    if (Platform.OS !== 'web' || Platform.OS !== 'android') {
      Linking.openURL(`google.navigation:q=${lat},+${long}`)
    }
    if (Platform.OS !== 'ios') {
      Linking.openURL(`maps://app?saddr=My+Location&daddr=${lat},+${long}`)
    }
  }



  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    setFSS(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  }

  function getRandomImage(){
    if(currentImages.length < 1) return
    let temp = currentImages
    if(temp.length < 2) {
      let tempArr = []
      for(let i = 0; i < events.length; i++){
        let uri = grabRandomURI()
        tempArr[i] = uri
      }
      console.log("we are updating currentImages to this!!!", tempArr)
      setCurrentImages(tempArr)
      // return tempArr[Math.floor(Math.random() * tempArr.length)]
    }
    console.log("this is the currentImages", temp, temp.length, images)
    let imageIdx = (Math.floor(Math.random() * currentImages.length))
    let newArr = currentImages.splice(imageIdx, 1)
    setCurrentImages(newArr)
    return temp[imageIdx]
  }

  function renderItem(item, index){
    if(index % 2 !== 0) return
    console.log("this is the item", item)
    //console.log("these are the images in item", images, index)
    const randomURI1 = images[Math.floor(Math.random() * images.length)]
    const randomURI2 = images[Math.floor(Math.random() * images.length)]
    //console.log("this the randomURI", randomURI)
    const event = item.event
    return (
      <View>
        <TwoShows 
          toWebsite={toWebsite}
          toDirections={toDirections}
          makeStyles={makeStyles}
          FS={FS}
          FSS={FSS}
          event={event} 
          event2={events[index+1]}
          randomURI1={randomURI1}
          randomURI2={randomURI2}/>
      </View>
    )
  }

  function renderSmallItem(item, index){
    //console.log("this is the item", item)
    const randomURI = images[index]
    //console.log(item.event)
    const event = item.event
    return (
    <View style={{ width: FS.width, justifyContent: 'center', alignItems: 'center', marginBottom: FSS * 50 }}>
      <View style={{}}>
        <Image
            source={randomURI}
            style={{height: FS.height / 2, width: FS.width - 25}}// my scaling. pretty good right. uep. ik
          >
        </Image>
        <LinearGradient
              colors={['rgba(0,0,0,0.9)', 'transparent']}//rgba(0,0,0,0.9)
              style={{ width: FS.width - 25, height: FS.height/3.5, position: 'absolute', top: 0,}}>
          </LinearGradient>
          <LinearGradient
              colors={['transparent', 'rgba(0,0,0,0.9)']}//rgba(0,0,0,0.9)
              style={{ width: FS.width - 25, height: FS.height/3.5, position: 'absolute', bottom: 0,}}>
          </LinearGradient>
          <View style={{position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly', left: 10}}>
          {/* {event?.lat && 
          <TouchableOpacity onPress={() => toDirections(event.lat, event.long)} style={{height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center', }}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS }}>Directions</Text>
          </TouchableOpacity>
          } */}
          {event?.tickets && 
          <TouchableOpacity onPress={() => toWebsite(event?.tickets)} style={{height: 40, width: 200 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center',}}>
              <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS}}>Tickets</Text>
          </TouchableOpacity>
          }
        </View>
        <View style={{position: 'absolute', left: 10, bottom: 10, }}>
          <Text style={[styles.name, ]}>{event.name}</Text>
          <Text style={[styles.location, ]}>{event.location} - {event.time || 'TBD'}</Text>
        </View>
        <View style={{backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1}}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 30 * FSS,color: 'black', textAlign: 'center', width: FSS * 85}}>{event.date}</Text>
        </View>
      </View> 
        {/* <Image
            source={randomURI}
            style={{height: FS.height / 2.9, width: FS.width < 900 ? FS.width - 50 : FS.width /1.5, minHeight: 300, alignSelf: 'center'}}// my scaling. pretty good right. uep. ik
        />
         <View style={{ backgroundColor: 'white', position: 'absolute', right: 35, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1}}>
              <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS,color: 'black',padding: 10, textAlign: 'center', width: FSS * 80}}>{event.date}</Text>
            </View>
          <View style={{ alignItems:'center', alignSelf: 'center', flexDirection: 'row',}}>
            <View>
              <Text style={styles.name} numberOfLines={1}>{event.name}</Text>
              <Text style={styles.location}>{event.location}</Text>
              <Text style={styles.time}>{event.time}</Text>
            </View>
            <View style={{justifyContent: 'space-evenly', alignSelf: 'center', top: 10, left: 10}}>
            {event?.lat && 
              <TouchableOpacity onPress={() => toDirections(event.lat, event.long)} style={{height: 40, width: 100, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center',top: event?.website ? 10 : null}}>
                <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS }}>Directions</Text>
              </TouchableOpacity>
            }
            {event?.website && 
              <TouchableOpacity onPress={() => toWebsite(event?.website)} style={{height: 40, width: 100, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS}}>Website</Text>
              </TouchableOpacity>
            }
            </View>
            {/* <View style={{height: 50, width: 125,backgroundColor:'white', borderRadius: 30, justifyContent: 'center', alignItems:'center',marginTop: 10}}>
              <Text style={{padding:5, fontWeight:'bold'}}>Coming soon...</Text>
            </View> 
          </View> */}   

      
    </View>
    )
  }

  function grabRandomURI(){
    const URIs = [
      require('../assets/bandImages/4some.jpeg'),
      require('../assets/bandImages/Horizontal/H3.png'),
      require('../assets/bandImages/Horizontal/H1.png'),
      require('../assets/bandImages/Horizontal/H5.png'),
      require('../assets/bandImages/Horizontal/H4.png'),
    ]
    const index = Math.round(getRandomArbitrary(0, URIs.length-1))
    return URIs[index] 
  }

  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  const listItems = events?.map((event, index) =>  
      FS.width < 900 ? renderSmallItem({event}, index) : renderItem({event}, index)
  );  

  const isIphone = FS.width < 900
  return (
    <View style={{marginTop: props.fromHome ? isIphone ? 150 : 125 : 50, justifyContent: 'center', alignItems: 'center'}}>
    {listItems} 
    {/* <FlatList
        data={events}
        renderItem={FS.width < 900 ? renderSmallItem :  renderItem}
    /> */}
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 40* fontScale, 
    color: 'white',
    marginTop: 50
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    paddingLeft: 5,
  },
  location: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale,  
    color: 'white',
    paddingLeft: 5
  },
  time: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white',
    padding: 5
  },
});