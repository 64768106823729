import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, Image } from 'react-native';
import { useFonts } from 'expo-font';
import { LinearGradient } from 'expo-linear-gradient';
import { useEffect, useState, useRef } from 'react';

const { height, width } = Dimensions.get('window');

export default function Band() {
  const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
        'CG-Times': require('../assets/CG Times Regular font.otf'),
  });
  const [FS, setFS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const isIphone = FSS.width < 900

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 640, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  const scaledFontSize = FSS.width < 900 ? 25 * FS : 15 * FS 
  
  return (
    <View style={styles.container}>
      
      <View style={{flexDirection: 'row', width: FSS.width, height: FSS.height,}}>
        <View style={{flex: 1,}}>
          <Image
            source={require('../assets/bandImages/Vertical/V2.png')}
            style={{height: FSS.height / 1.5, width: FSS.width/3,}}// my scaling. pretty good right. uep. ik
          />
          <Image
            source={require('../assets/bandImages/Vertical/V3.png')}
            style={{height: FSS.height / 1.5, width: FSS.width/3,}}// my scaling. pretty good right. uep. ik
          />
          <Image
            source={require('../assets/bandImages/Vertical/V.png')}
            style={{height: FSS.height / 1.5, width: FSS.width/3,}}// my scaling. pretty good right. uep. ik
          />
        </View>
        
        <View style={{flex: 2}}>
          <Image
            source={require('../assets/bandImages/BandPic.jpeg')}
            style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
          />
          <Image
            source={require('../assets/bandImages/Horizontal/H1.png')}
            style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
          />
          <Image
            source={require('../assets/bandImages/Horizontal/H.png')}
            style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
          />
          <Image
            source={require('../assets/bandImages/Horizontal/H3.png')}
            style={{height: FSS.height / 2, width: FSS.width/1.5,}}// my scaling. pretty good right. uep. ik
          />
        </View>
      </View>

      <LinearGradient
              colors={['rgba(0,0,0,0.9)', 'transparent', ]}//rgba(0,0,0,0.9)
              style={{ width: FSS.width, height: isIphone ? FSS.height : FSS.height*1.5, position: 'absolute', top: 0,}}>
      </LinearGradient>


      <Text style={{textAlign: 'center' ,fontFamily: 'CG-Times Bold', fontSize: FSS.width < 900 ? (45 * FS) : (35 * FS), color: 'white', position: 'absolute', left: 20, top: FSS.width < 800 ? 50 : 75,}}>Four Hetty Gentlemen from NC</Text>
      <View style={{position: 'absolute', top: FSS.width < 900 ? 150 : 150}}>
        <View style={{ backgroundColor: '#eafbf2', padding: 30, borderRadius: 10, width: FSS.width < 900 ? FSS.width/1.5 : FSS.width /2, bottom: 0, opacity: .8, }}>
          <Text style={{ fontFamily: 'CG-Times', fontSize: scaledFontSize, color: 'black'}}>
            Jacoozy is a improvisational, funk, and alternative band formed in Boone, NC in 2021. Featuring guitarists <Text style={{fontFamily: 'CG-Times Bold', fontSize: scaledFontSize, color: 'black'}}>Killian Wright</Text> and <Text style={{fontFamily: 'CG-Times Bold', fontSize: scaledFontSize, color: 'black'}}>Will Fentress</Text>, on the keys <Text style={{fontFamily: 'CG-Times Bold', fontSize: scaledFontSize, color: 'black'}}>Isaac McMurry</Text>, and drummer <Text style={{fontFamily: 'CG-Times Bold', fontSize: scaledFontSize, color: 'black'}}>Pauly Scott</Text>, all members of the band contribute vocals. With a vast range of influences ranging from classic improvisational bands to pop, Jacoozy shows are known for their high energy, precision, and unpredictability. 
          </Text>
        </View>

        <View style={{ backgroundColor: '#eafbf2', padding: 30, borderRadius: 10, width: FSS.width < 900 ? FSS.width/1.5 : FSS.width /2, bottom: 0, opacity: .8, marginTop: 40}}>
          <Text style={{ fontFamily: 'CG-Times', fontSize: scaledFontSize, color: 'black', }}>
            Beginning on the House Show circuit in Boone, Jacoozy quickly built a devoted local following and were soon appearing in local clubs such as Boone Saloon, Tapp Room, and Ransom Pub. Ever versatile and ready to meet any challenge, Jacoozy appeared in musical costume as Pink Floyd at the Halloween party. Fans have also enjoyed their shows in Charlotte, Raleigh, Winston-Salem, and Wilmington.
          </Text>
          <Text style={{ fontFamily: 'CG-Times', fontSize: scaledFontSize, color: 'black', paddingTop: 20, }}>
            Their self-titled EP was released in 2022. Recorded in Asheville, NC, it includes the tracks Cold Hearted, Lilly, and Dog Days. 
          </Text>
        </View>
      </View>
  
    </View>
  )
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 50 * fontScale, 
    color: 'white',
    paddingTop: 200,
    width: width / 1.3
  },
});
