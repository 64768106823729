import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { useFonts } from 'expo-font';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable, Animated } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function FadeInText(props) {
    const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    const [fadeAnim] = useState(new Animated.Value(0));
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('./assets/cgtr65w.otf'),
      });

    useEffect(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: props.duration,
      }).start();
    }, []);
  
    return (
      <Animated.View
        style={{
            opacity: fadeAnim,
            borderBottomWidth: 1,
            borderBottomColor: 'white',
        }}
      >
        <Text style={styles.textStyle}>{props.children}</Text>
      </Animated.View>
    );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    borderBottomColor: 'white',
    borderBottomWidth: 1
  },
  textStyle:{
    fontFamily: 'CG-Times Bold', 
    fontSize: 25 * fontScale, 
    color: 'white'
  },
  
  
});
