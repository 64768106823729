import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, FlatList, Dimensions, ImageBackground } from 'react-native';
import { useFonts } from 'expo-font';
import EventList from '../common/EventList';
import ShowsHeader from '../common/ShowsHeader'

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function Shows() {
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
    });
    const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
    const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  
    useEffect(() => {
      window.addEventListener('resize', changeTitleSize);
      return () => {
          window.removeEventListener('resize', changeTitleSize);
      }
    }, []);
  
    function changeTitleSize(){
      setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
      setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    }
    

  return (
    <View style={styles.container}>
        <ShowsHeader/>
        <View style={{bottom: FSS.width < 900 ? FS * 225 : FS * 250}}>
        <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 40* FS, color: 'white',zIndex: 999}}>Upcoming Shows</Text>
        </View>
        <View style={{marginTop: 0}}>
          <EventList/>
          </View>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 40* fontScale, 
    color: 'white',
    marginTop: -350,
    zIndex: 999
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  location: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale,  
    color: 'white',
    padding: 5
  },
  time: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white',
    padding: 5
  },
});
