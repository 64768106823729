import React, {createContext, Component} from 'react';

export const MainContext = createContext();

class MainContextProvider extends Component {
    state = { 
        scrollPosition: 0
     } 

    //  componentDidUpdate(prevProps, prevState) {
    //     if (prevState.scrollPosition !== this.state.scrollPosition) {
    //       //console.log('scrollPosition state has changed. ' + JSON.stringify(this.state.scrollPosition))
    //     } else //console.log('scrollPosition state has NOT changed.')
    //   }


    setScrollPosition = (y) => {
      this.setState({scrollPosition: y})
    }

    render() { 
        return ( 
            <MainContext.Provider 
                value={{
                ...this.state, 
                setScrollPosition: this.setScrollPosition
            }}
            >
                {this.props.children}
            </MainContext.Provider>
        );
    }
}
 
export default MainContextProvider;

