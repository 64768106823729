import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import FadeInText from './FadeInText';


export default function HamburgerMenuExpanded(props) {

  return (
    <Pressable style={{height:props.height, width: props.width, backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute', zIndex: 999}}>
      <TouchableOpacity style={styles.fadeText} onPress={() => props.switchTabs('HomeScreen', true)}> 
        <FadeInText duration={500}>
          <Text>Home</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={styles.fadeText} onPress={() => props.switchTabs('ShowsScreen', true)}> 
        <FadeInText duration={1000}>
          <Text>Shows</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={styles.fadeText} onPress={() => props.switchTabs('BandScreen', true)}> 
        <FadeInText duration={1500}>
          <Text>Band</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={styles.fadeText} onPress={() => props.switchTabs('MediaScreen', true)}> 
        <FadeInText duration={2000}>
          <Text>Media</Text>
        </FadeInText>
      </TouchableOpacity>
      <TouchableOpacity style={styles.fadeText} onPress={() => props.switchTabs('ContactScreen', true)}> 
        <FadeInText duration={2500}>
          <Text>Contact</Text>
        </FadeInText>
      </TouchableOpacity>
    </Pressable>
  );
}

const styles = StyleSheet.create({
    fadeText: {
        flex: 5,
        alignItems: 'center',
        justifyContent: 'center'
      }
});
