import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { Audio } from 'expo-av';
import ProgressBar from './ProgressBar';
import usePrevious from '../usePrevious';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function SongBubble(props){
  const { isPlaying, setIsPlaying, myProgress, setMyProgress, currentSongName} = props;
  const [title, setTitle] = useState("Jacoozy")
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
        'CG-Times': require('../assets/CG Times Regular font.otf'),
      });
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [soundState, setSoundState] = useState();
  const [Loaded, SetLoaded] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const sound = useRef(new Audio.Sound());
  const [currentDuration, setCurrentDuration] = useState(0)
  const [audioProgress, setAudioProgress] = useState(0)
  const previous = usePrevious(currentSongName)

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  useEffect(() => {
    //console.log("sound interval", sound)
    LoadAudio();
  }, [sound]);

  useEffect(() =>{
    //console.log("prev", previous, props.songPath)
    if(currentSongName !== previous) {
      //console.log("are we here")
      sound.current.unloadAsync();
      LoadAudio()
      setAudioProgress(0)
      setCurrentDuration(0)
      stop()
    }
  }, [currentSongName, props.songPath]);

  useEffect(() => {
    if(currentDuration === 0 ) return setAudioProgress(0)
    if(audioProgress>props.totalDuration) {
      setAudioProgress(0)
    }
  }, [audioProgress, currentDuration]);

  useEffect(() => {
  }, [isPlaying]);

  const PlayAudio = async () => {
    try {
      const result = await sound.current.getStatusAsync();
      if (result.isLoaded) {
        if (result.isPlaying === false) {
          sound.current.playAsync();
        }
      }
    } catch (error) {}
  };

  const PauseAudio = async () => {
    try {
      const result = await sound.current.getStatusAsync();
      if (result.isLoaded) {
        if (result.isPlaying === true) {
          sound.current.pauseAsync();
        }
      }
    } catch (error) {}
  };

  const LoadAudio = async () => {
    SetLoading(true);
    const checkLoading = await sound.current.getStatusAsync();
    //console.log("checkLoading", checkLoading, sound)
      try {
        //console.log("are we eve here", props.songPath)
        const result = await sound.current.loadAsync(props.songPath, {}, true);
        //console.log("we got a new song loaded", result)
        if (result.isLoaded === false) {
          SetLoading(false);    
        } else {
          SetLoading(false);
          SetLoaded(true);
        }
      } catch (error) {  
        //console.log("error pls", error)
        SetLoading(false);
      }
 
  };

  function playSong(totalDuration){
    // playSound(require('../assets/Again.mp3'))
    isPlaying ? PauseAudio() : PlayAudio()
    setIsPlaying( isPlaying ? false : true)
    isPlaying ? stop() : interval(totalDuration); updateAudioProgress(totalDuration)
  }
  
  const updateAudioProgress = (totalDuration) => {
    //assign interval ref here
    audioProgressRef.current = setInterval(() => {
      if(isPlaying) return
      getAudioProgress(totalDuration);
    }, 100);
  };

  const intervalRef = useRef(); //create a ref for interval
  const audioProgressRef= useRef()

  const start = (totalDuration) => {
    setCurrentDuration((prev) => {
      if (prev === totalDuration) {
        stop();
        setIsPlaying(false)
        return 0;
      }
      return prev + 1;
    });
  };

  const interval = (totalDuration) => {
    //assign interval ref here
    intervalRef.current = setInterval(() => {
      if (currentDuration === totalDuration) {
        stop();
        setIsPlaying(false)
        return;
      }
      start(totalDuration);
    }, 1000);
  };

  function getAudioProgress(totalDuration){
    setAudioProgress((prev) => {
      return prev + .10;
    })    
  }

  const stop = () => {
    //clear the interval ref
    clearInterval(intervalRef.current);
    clearInterval(audioProgressRef.current);
  };
  return(
    <View style={{justifyContent: 'center', backgroundColor: 'red'}}>
      <ProgressBar progress={audioProgress} totalDuration={props.totalDuration}/>
      <TouchableOpacity onPress={() => playSong(props.totalDuration)} style={{ position: 'absolute', right: 10}}>
        <MaterialCommunityIcons
          name={isPlaying ? "pause-circle-outline" : "play-circle-outline"}
          size={isPlaying ? 40 : 40}
          color="black"
        />
      </TouchableOpacity> 
      {/* <Text style={{fontSize: 20,fontFamily: 'CG-Times', position: 'absolute', right: 15}}>{currentDuration}/{props.totalDuration}</Text> */}

    </View>
  )
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 100 * fontScale, 
    color: 'white'
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    padding: 10,
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    flexDirection: 'row',
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 16,
    fontFamily: 'CG-Times', 
    marginLeft: 10
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
