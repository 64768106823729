import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, FlatList, TextInput, Pressable, TouchableOpacity } from 'react-native';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState, useRef } from 'react';
import { child, ref, set, get, getDatabase, onValue, update} from "firebase/database";
import { configPassword } from '../config';

const { height, width } = Dimensions.get('window');

export default function SecretPage() {
  const navigation = useNavigation();
  const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const [password, setPassword] = useState()
  const [FS, setFS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet



  function onSubmit(){
    if(password === configPassword) navigation.navigate('SecretPageScreen')
  }

  
  const isReadyToSend = password
  return (
    <View style={[styles.container, {height: FSS.height}]}>
        <TextInput
          onChangeText={setPassword}
          style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
          value={password}
        />
        <TouchableOpacity activeOpacity={!isReadyToSend ? .2 : .2} disabled={!isReadyToSend} onPress={() => !isReadyToSend ? null : onSubmit()}
          style={{width: 300, height: 50, backgroundColor: !isReadyToSend ? 'gray' : '#56dc99', justifyContent: 'center', alignItems: 'center', borderRadius: 10, opacity: !isReadyToSend ? .4 : 1, marginBottom: 50}}>
           <Text style={{color: 'black', fontSize: 18}}>Submit</Text>
        </TouchableOpacity>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#92e9be'
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 50 * fontScale, 
    color: 'white',
    paddingTop: 200,
    width: width / 1.3
  },
});
