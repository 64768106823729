import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Animated, Dimensions, Linking, Image} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef, memo, useContext } from 'react';
import Svg, { Path } from 'react-native-svg';
import { TouchableOpacity } from 'react-native-web';
import { Audio } from 'expo-av';
import EventList from '../common/EventList';
import SongBubble from '../common/SongBubble';
import { LinearGradient } from 'expo-linear-gradient';
import ProgressBar from '../common/ProgressBar';
import SongPlayer from '../common/SongPlayer';
import MusicButton from '../common/MusicButton';
import PhoneSongPlayer from '../common/PhoneSongPlayer';
import { child, ref, set, get, getDatabase, onValue,} from "firebase/database";
import { db } from '../config';
import { MainContext } from '../Contexts/mainContext';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function Home(props) {// you need to send the video killian sent to bluehost to figure that out.
  const [title, setTitle] = useState("Jacoozy")
  const [isPlaying, setIsPlaying] = useState(false)
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
      });
    const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [soundState, setSoundState] = useState();
  const [Loaded, SetLoaded] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const sound = useRef(new Audio.Sound());
  const [currentDuration, setCurrentDuration] = useState(0)
  const [songCounts, setSongCounts] = useState([])
  const [fadeAnim] = useState(new Animated.Value(0));
  const [eventListFadeAnim] = useState(new Animated.Value(0));
  const [eventListTriggered, setEventListTriggered] = useState(false)
  const {scrollPosition, setScrollPosition} = useContext(MainContext)

  useEffect(() => {
    readData()
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  useEffect(() => {
    console.log("SP", scrollPosition)
    if(scrollPosition >= 550) animateEventList()
    
  }, [scrollPosition]);
 
  // useEffect(() => {
  //   //console.log("we should be scrolling", props.isScrolling)
  //   if(props.isScrolling) weScrolled()
  // }, [props.isScrolling]);

  useEffect(() => {
    const catchEmAll = async () => {
      await fetch('https://jacoozy-api.onrender.com/webhook', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        passphrase: "huyter"
      }),
    })
    readData()
    }
    catchEmAll()
  },[])

  function readData(){
    const showsRef = ref(db, 'counts');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      //console.log("this the data tooo", data)
      setSongCounts(data)
    });
  }

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
    //console.log(Math.min(window.innerWidth))
  }

  const handleClick = (provider) => {
    let link = null
    if(provider === 'Spotify') link = 'https://open.spotify.com/artist/65nqeHILTPeRfAMMSMqFl4'
    if(provider === 'Apple') link = 'https://music.apple.com/us/artist/jacoozy/1602621098'
    if(provider === 'Amazon') link = 'https://music.amazon.com/artists/B09PKQZSB8/jacoozy?marketplaceId=ATVPDKIKX0DER&refMarker=dm_wcp_af_r&ref=dm_sh_sUFFPEOk3C7rBi0a6XYKFwj1c&musicTerritory=US'
    Linking.canOpenURL(link).then(supported => {
      if (supported) {
        Linking.openURL(link);
      } else {
        //console.log("Don't know how to open URI: ");
      }
    });
  };

  function weScrolled(){
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 2500,
    }).start();
  }

  function animateEventList(){
    // setEventListTriggered(true)
    Animated.timing(eventListFadeAnim, {
      toValue: 1,
      duration: 2500,
    }).start();
  }



  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', }}>
        <View style={{flexDirection: 'row', width: FSS.width, justifyContent: 'center', }}>
          {/* header */}
          <View style={{position: 'absolute', zIndex: 999, alignSelf: 'center', top: FSS.width < 900 ? 100 : 150}}>
            <View style={{alignSelf:'center',}}>
            <Text style={styles.title}>Jacoozy</Text>
            </View>

            {/* Stream now button */}
            <View style={{flexDirection: 'row', justifyContent: 'center', paddingBottom: 50, alignItems: 'center', bottom: 20}}>
              <MusicButton title='Apple Music' onPress={() => handleClick('Apple')}/>
              <View style={{paddingLeft: FSS.width < 900 ? 30 : 90, paddingRight: FSS.width < 900 ? 30 : 90}}>
                <MusicButton title='Spotify' onPress={() => handleClick('Spotify')}/>
              </View>
              <MusicButton title='Amazon Music' onPress={() => handleClick('Amazon ')}/>
            </View>

             {/* Top 5 Songs */}
             {FSS.width < 900 ? <PhoneSongPlayer songCounts={songCounts}/> :  <SongPlayer songCounts={songCounts}/>}
            
            
            {/* About  - I want this to be gone originally, but fade in once you scroll*/}
            {/* <Animated.View style={{opacity: fadeAnim,}}>
              <View style={{paddingHorizontal: 10, marginTop: FSS.width < 900 ? FS * 70 : 150, opacity: fadeAnim, paddingBottom: 20}}>
                <Text style={{textAlign: 'center' ,fontFamily: 'CG-Times Bold', fontSize: FSS.width < 900 ? (45 * FS) : (38 * FS), color: 'white',}}>
                  Four Hetty Gentlemen from NC
                  </Text>
              </View>
              <View style={{ width: FSS.width < 900 ? FSS.width/1.5 : FSS.width /2, top: 10, alignSelf: 'center', }}>
                  <View style={{ padding: FSS.width < 900 ? 5 : 25,   }}>
                    <Text style={{ fontFamily: 'CG-Times', fontSize: 17 * FS, color: 'white', fontWeight: 'bold'}}>
                    Jacoozy is a five-piece improvisational band originating in Boone, NC, blending styles from rock, jazz, and indie. 
                    </Text>
                    <Text> </Text>
                    <Text style={{ fontFamily: 'CG-Times', fontSize: 17 * FS, color: 'white', fontWeight: 'bold'}}>
                    A group of proficient musicians with dynamic improvisation and captivating live shows, whose core following has quickly spread across North Carolina, and into the southeast.           </Text>
                </View>
              </View>
            </Animated.View> */}

          </View>


          <View style={{width: FSS.width}}>
            <Image
              source={require('../assets/bandImages/purpleBand.jpg')}
              style={{height: FSS.height / .8, width: FSS.width,}}// my scaling. pretty good right. uep. ik
            />
          </View>
          <LinearGradient
              colors={['rgba(0,0,0,0.9)', 'transparent']}//rgba(0,0,0,0.9)
              style={{ width: FSS.width, height: FSS.height/1.5, position: 'absolute', top: 0,}}>
          </LinearGradient>
          <LinearGradient
              colors={['transparent', 'rgba(0,0,0,0.9)']}//rgba(0,0,0,0.9)
              style={{ width: FSS.width, height: FSS.height/1.5, position: 'absolute', bottom: 0,}}>
          </LinearGradient>
        </View>

  
  


      {/* Live Shows */}
      <Animated.View style={{opacity: eventListFadeAnim,}}>
      <EventList fromHome/>
      </Animated.View>
    </View>
  );
}


const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 90 * fontScale, 
    color: '#92e9be', //92e9be
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    paddingRight: 20,
    paddingLeft: 20,
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});
