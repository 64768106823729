import * as React from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTab from './HomeTab';
import Band from './Tabs/Band';
import Contact from './Tabs/Contact';
import Shows from './Tabs/Shows';
import Media from './Tabs/Media';
import RootScreen from './RootScreen'
import SecretPage from './Tabs/SecretPage';
import SecretPageLogin from './Tabs/SecretPageLogin';

const Stack = createNativeStackNavigator();

function RootContainer() {
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          // `MyAppName - ${options?.title ?? route?.name}`
          'Jacoozy'
      }}
    >
      <Stack.Navigator>
        <Stack.Screen name="RootScreen" component={RootScreen} options={{ headerShown: false, }}/>
        <Stack.Screen name="HomeScreen" component={HomeTab} options={{ headerShown: false, }}/>
        <Stack.Screen name="BandScreen" component={Band} options={{ headerShown: false, }}/>
        <Stack.Screen name="ContactScreen" component={Contact} options={{ headerShown: false, }}/>
        <Stack.Screen name="ShowsScreen" component={Shows} options={{ headerShown: false, }}/>
        <Stack.Screen name="MediaScreen" component={Media} options={{ headerShown: false, }}/>
        <Stack.Screen name="SecretPageLoginScreen" component={SecretPageLogin} options={{ headerShown: false, }}/>
        <Stack.Screen name="SecretPageScreen" component={SecretPage} options={{ headerShown: false, }}/>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default RootContainer;