import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, useWindowDimensions, Dimensions, Linking, ScrollView} from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native-web';
import Svg, { Path } from 'react-native-svg';
import WebsiteHeader from './WebsiteHeader'
import WebsiteTabs from './WebsiteTabs'
import HamburgerMenu from './HamburgerMenu'
import Wave from './Wave';
import Instagram from './common/Instagram';

const { height, width } = Dimensions.get('window');
// const fontScale = c

export default function TopHeader(props) {
  const {menuActivated, switchTabs, setMenuActivated, headerTriggerd, setHeaderTriggered} = props;
  const [FS, setFS] = useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})

  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }


  return (
    <View style={styles.container}>
     <Wave width={FS.width} height={205} />
     {FS.width < 900 ? <Instagram smallScreen/> : <Instagram/>}
      <WebsiteHeader switchTabs={switchTabs}/>
      {FS.width < 900 ? 
      <HamburgerMenu menuActivated={menuActivated} setMenuActivated={setMenuActivated}/> 
      :
      <View style={{ flex: 1,right: FS.width/6, top: 40,zIndex: 101 }}>
      <WebsiteTabs 
        headerTriggerd={headerTriggerd} 
        setHeaderTriggered={setHeaderTriggered} 
        switchTabs={switchTabs}
      />
      </View>
      }

    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    height: 0,
    zIndex: 100
  },
  fadeText: {
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

