import { StatusBar } from 'expo-status-bar';
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Linking, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function TwoShows(props) {
  const {event, event2, toDirections, FS, FSS, randomURI1,randomURI2} = props
  const styles = makeStyles(Math.min(window.innerWidth / 320, window.innerHeight / 640)); // pass in fontScale to the StyleSheet
  useEffect(() => {
    console.log("this is the event 1", event)
    console.log("this is the event 2", event2)
  },[event,event2])
  console.log("this is the ticket url", event?.tickets)

  function toWebsite(url){
    console.log("this is the url", url)
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: ", url);
      }
    });
  }

  return (
    <View style={{flexDirection: 'row', width: FS.width,  justifyContent: 'center', marginBottom: 20}}>
    <View style={{marginRight: 20}}>
      <Image
          source={randomURI1}
          style={{height: FS.height / 2, width: FS.width/2.5}}// my scaling. pretty good right. uep. ik
        >
      </Image>
      <LinearGradient
            colors={['rgba(0,0,0,0.9)', 'transparent']}//rgba(0,0,0,0.9)
            style={{ width: FS.width/2.5, height: FS.height/3.5, position: 'absolute', top: 0,}}>
        </LinearGradient>
        <LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.9)']}//rgba(0,0,0,0.9)
            style={{ width: FS.width/2.5, height: FS.height/3.5, position: 'absolute', bottom: 0,}}>
        </LinearGradient>
        <View style={{position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly',left: 10}}>
        {/* {event?.lat && 
        <TouchableOpacity onPress={() => toDirections(event.lat, event.long)} style={{height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center', }}>
          <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS }}>Directions</Text>
        </TouchableOpacity>
        } */}
        {event?.tickets && 
        <TouchableOpacity onPress={() => toWebsite(event?.tickets)} style={{height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center',}}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS}}>Tickets</Text>
        </TouchableOpacity>
        }
      </View>
      <View style={{position: 'absolute', left: 10, bottom: 25,}}>
        <Text style={[styles.name, ]}>{event.name}</Text>
        <Text style={[styles.location, ]}>{event.location} - {event.time || 'TBD'}</Text>
      </View>
      <View style={{backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1}}>
          <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS,color: 'black', textAlign: 'center', width: FSS * 65}}>{event.date}</Text>
      </View>
    </View> 


    <View style={{}}>
      <Image
          source={randomURI2}
          style={{height: FS.height / 2, width: FS.width/2.5}}// my scaling. pretty good right. uep. ik
        >
      </Image>
      <LinearGradient
          colors={['rgba(0,0,0,0.9)', 'transparent']}//rgba(0,0,0,0.9)
          style={{ width: FS.width/2.5, height: FS.height/3.5, position: 'absolute', top: 0,}}>
      </LinearGradient>
      <LinearGradient
          colors={['transparent', 'rgba(0,0,0,0.9)']}//rgba(0,0,0,0.9)
          style={{ width: FS.width/2.5, height: FS.height/3.5, position: 'absolute', bottom: 0,}}>
      </LinearGradient>
        <View style={{position: 'absolute', top: 10, flexDirection: 'row', justifyContent: 'space-evenly', left: 10}}>
        {/* {event2?.lat && 
        <TouchableOpacity onPress={() => toDirections(event2?.lat, event2?.long)} style={{height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center', }}>
          <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS }}>Directions</Text>
        </TouchableOpacity>
        } */}
        {event2?.tickets && 
        <TouchableOpacity onPress={() => toWebsite(event2?.tickets)} style={{height: 75, width: 100 * FSS, backgroundColor: '#56dc99', borderRadius: 10, justifyContent: 'center', alignItems: 'center',}}>
            <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 15 * FSS}}>Tickets</Text>
        </TouchableOpacity>
        }
      </View>
      <View style={{position: 'absolute', left: 10, bottom: 20,}}>
        <Text style={[styles.name, ]}>{event2?.name}</Text>
        <Text style={[styles.location, ]}>{event2?.location} - {event2?.time || 'TBD'}</Text>
      </View>
      <View style={{backgroundColor: 'white', position: 'absolute', right: 10, top: 10, justifyContent: 'center', alignItems: 'center', aspectRatio: 1}}>
          <Text style={{ fontFamily: 'CG-Times Bold', fontSize: 20 * FSS,color: 'black', textAlign: 'center', width: FSS * 65}}>{event2?.date}</Text>
      </View>
     
    </View> 
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    position: 'absolute',
    height: 50,
    width: 50,
    borderRadius: 10,
    backgroundColor: 'transparent',
    right: 0,
    top: 40
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 40* fontScale, 
    color: 'white',
    marginTop: 50
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    paddingLeft: 5,
  },
  location: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale,  
    color: 'white',
    paddingLeft: 5
  },
  time: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 20 * fontScale, 
    color: 'white',
    padding: 5
  },
});



