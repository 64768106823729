import * as React from 'react';
import { View, StyleSheet, Button, Dimensions, Text, TouchableOpacity } from 'react-native';
import { Video, ResizeMode } from 'expo-av';
import { useFonts } from 'expo-font';
import  { useState, useEffect, useRef, useContext } from 'react'

export default function MusicButton(props) {
  const [fontsLoaded] = useFonts({
    'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet
  const [FS, setFS] = useState(Math.min(window.innerWidth / 320, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})


  useEffect(() => {
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);

  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 320, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  return (
    <View>
       <TouchableOpacity onPress={props.onPress}>
          <View style={styles.button}>
              <Text style={styles.buttonText}>{props.title}</Text>
           </View>
        </TouchableOpacity>
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 90 * fontScale, 
    color: '#92e9be', //92e9be
  },
  name: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 30 * fontScale,  
    color: 'white',
    padding: 5,
  },
  button: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eafbf2',
    borderRadius: 30,
    top: 25
  },
  buttonSong: {
    width: 375,
    height: 50,
    padding: 10,
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 30,
    flexDirection: 'row',
    marginTop: 20,
    alignSelf :'center'
  },
  buttonText: {
    fontSize: 15,
    fontFamily: 'CG-Times Bold', 
  },
  buttonSongText: {
    fontSize: 20,
    fontFamily: 'CG-Times Bold', 
    marginLeft: 15
  },
  email: {
    color: 'white', 
    fontFamily: 'CG-Times Bold', 
    position: 'absolute', 
    bottom: 25, 
    fontSize: 17
  }
});

