import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, FlatList, TextInput, Pressable, TouchableOpacity } from 'react-native';
import { useFonts } from 'expo-font';
import { useEffect, useState, useRef } from 'react';
import { child, ref, set, get, getDatabase, onValue, update} from "firebase/database";
import { db } from '../config';

const { height, width } = Dimensions.get('window');

export default function SecretPage() {
  const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('../assets/cgtr65w.otf'),
  });
  const [addingShow, setAddingShow] = useState()
  const [firstRender, setFirstRender] = useState(true)
  const [shows, setShows] = useState()
  const [numberInList, setNumberInList] = useState()
  const [venueName, setVenueName] = useState()
  const [cityState, setCityState] = useState()
  const [date, setDate] = useState()
  const [time, setTime] = useState()
  const [ticketURL, setTicketURL] = useState()
  const [forceShowUpdate, setForceShowUpdate] = useState()
  const [FS, setFS] = useState(Math.min(window.innerWidth / 640, window.innerHeight / 640))
  const [FSS, setFSS] =  useState({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  const styles = makeStyles(FS); // pass in fontScale to the StyleSheet


  useEffect(() => {
    if(firstRender) return;
    console.log("we are about to write some data!")
    writeData()
  }, [forceShowUpdate]);

  useEffect(() => {
    setFirstRender(false)
    readData()
    window.addEventListener('resize', changeTitleSize);
    return () => {
        window.removeEventListener('resize', changeTitleSize);
    }
  }, []);


  function changeTitleSize(){
    setFS(Math.min(window.innerWidth / 640, window.innerHeight / 640))
    setFSS({ "width": Math.min(window.innerWidth), "height": Math.min(window.innerHeight)})
  }

  function readData(){
    const showsRef = ref(db, 'shows');
    onValue(showsRef, (snapshot) => {
      const data = snapshot.val();
      console.log("this the data", data)
      setShows(data);
    });
  }

  function writeData(){
    const data = shows
    const updates = {};
    const showsRef = ref(db);
    updates['/shows'] = data
    update(showsRef,updates);
  }

  function renderShow({item, index}){
    console.log("this is the item", item)
    return (
      <View style={{paddingBottom: 50, flexDirection: 'row'}}>
        <View style={{marginRight: 10, justifyContent: 'center', alignSelf: 'center'}}>
          <Text onPress={()=> deleteShow(index)}>Del</Text>
        </View>
        <View>
          <Text>{index}</Text>
          <Text>{item.name}, {item.location}</Text>
          <Text>{item.date}</Text>
          <Text>{item.time}</Text>
          <Text>{item.tickets}</Text>
        </View>
      </View>
    )
  }

  function deleteShow(index){
    setShows(prev => prev.filter((_, i) => i !== index) )
    setForceShowUpdate(prev => !prev)
  }

  function createShow(){
    setAddingShow(true)
  }

  function onSubmit(){
    let temp = shows;
    let show = {
      name: venueName,
      location: cityState || '',
      date: date ||  '',
      time: time || '',
      tickets: ticketURL || ''
    }
    console.log("this is the show!", show)
    console.log("whats this", temp?.length)
    if(temp?.length === undefined){
       temp = [show]
    } else {temp?.splice(numberInList, 0, show)}
    
    console.log("this is the new list", temp)
    setShows(temp)
    setForceShowUpdate(prev => !prev)// for some reason setting shows above isnt good enough?
    setNumberInList("")
    setVenueName("")
    setCityState("")
    setDate("")
    setTime("")
    setTicketURL("")
  }

  
  const isReadyToSend = (venueName || cityState || date || time || ticketURL) && numberInList
  return (
    <View style={[styles.container, {height: FSS.height}]}>
        <Text style={{textAlign: 'center' ,fontFamily: 'CG-Times Bold', fontSize: 50 * FS, color: 'white', width: FSS.width/1.1}}>Secret Page</Text>
        <Text onPress={() => addingShow ? setAddingShow(false) : createShow()}>{addingShow ? 'cancel' : 'Add show'}</Text>
        {addingShow && 
          <View>
            <TextInput
              placeholder=' Number in list'
              placeholderTextColor={'gray'}
              onChangeText={setNumberInList}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={numberInList}
            />
            <TextInput
              placeholderTextColor={'gray'}
              placeholder=' Venue Name'
              onChangeText={setVenueName}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={venueName}
            />
            <TextInput
              placeholderTextColor={'gray'}
              placeholder=' City, State'
              onChangeText={setCityState}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={cityState}
            />
            <TextInput
              placeholderTextColor={'gray'}
              placeholder=' Date'
              onChangeText={setDate}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={date}
            />
            <TextInput
              placeholderTextColor={'gray'}
              placeholder=' Time'
              onChangeText={setTime}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={time}
            />
            <TextInput
              placeholderTextColor={'gray'}
              placeholder=' Ticket URL'
              onChangeText={setTicketURL}
              style={{backgroundColor: 'white', height: 25, width: FSS.width < 900 ? FS * 500 : 400, borderRadius: 5, marginBottom: 10,}}
              value={ticketURL}
            />
            <TouchableOpacity activeOpacity={!isReadyToSend ? .2 : .2} disabled={!isReadyToSend} onPress={() => !isReadyToSend ? null : onSubmit()}
              style={{width: 300, height: 50, backgroundColor: !isReadyToSend ? 'gray' : '#56dc99', justifyContent: 'center', alignItems: 'center', borderRadius: 10, opacity: !isReadyToSend ? .4 : 1, marginBottom: 50}}>
                <Text style={{color: 'black', fontSize: 18}}>Submit</Text>
            </TouchableOpacity>
          </View>
        }
        <FlatList
          data={shows}
          renderItem={renderShow}
          extraData={forceShowUpdate}
        />
    </View>
  );
}

const makeStyles = fontScale => StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#92e9be'
  },
  title: {
    fontFamily: 'CG-Times Bold', 
    fontSize: 50 * fontScale, 
    color: 'white',
    paddingTop: 200,
    width: width / 1.3
  },
});
