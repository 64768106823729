import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, TouchableOpacity,Pressable} from 'react-native';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';



export default function WebsiteHeader(props) {
  const navigation = useNavigation();
    const [fontsLoaded] = useFonts({
        'CG-Times Bold': require('./assets/cgtr65w.otf'),
      });
    const [firstTapped, setFirstTapped] = useState()
    const [count, setCount] = useState(2)

  function secretTap(){
    console.log("we tappin", count)
    if(count === 0){
      setCount(prev => prev + 1)
      setFirstTapped(moment())
    }
    if(count === 1) setCount(prev => prev + 1)
    if(count === 2) setCount(prev => prev + 1)
    if(count === 3) setCount(prev => prev + 1)
    if(count === 4) setCount(prev => prev + 1)
    if(count === 5){
      let currentTime = moment()
      let threeSecondAgo = moment().subtract(3, 'seconds')
      console.log("its 5!",firstTapped > threeSecondAgo, firstTapped, threeSecondAgo )   
      setCount(0)
      if(currentTime === currentTime){
        navigation.navigate('SecretPageLoginScreen')
        //navigate to secret page here
      }

    }
  }

  return (
    <Pressable style={{backgroundColor: 'transparent', position: 'absolute', top:20, left: 50, zIndex: 101}}
    onPress={() => {props.switchTabs('HomeScreen', false, true), secretTap()}} >
        <Text style={{fontFamily: 'CG-Times Bold', fontSize: 40, color: '#92e9be'}}>Jacoozy</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({

});
