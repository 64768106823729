// https://www.youtube.com/watch?v=J95MC2Koymc&ab_channel=CatalinMiron
import  { useState, useEffect, useRef, useContext } from 'react'
import { StyleSheet, Text, View, FlatList, ImageBackground, Animated} from 'react-native';
import { useFonts } from 'expo-font';

const Progress = ({step, steps, height}) => {
  const animatedValue = useRef( new Animated.Value(-1000)).current;
  const reactive = useRef( new Animated.Value(-1000)).current;
  const [width, setWidth] = useState(0)

  useEffect(() =>{
    Animated.timing(animatedValue, {
      toValue: reactive,
      duration: 300,
      useNativeDriver: true
    }).start()
  },[])

  useEffect(() =>{
    reactive.setValue(-width + width * step / steps)
  },[step, width])

  return(
    <View style={{ height, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: height, overflow:'hidden',}}
      onLayout={e => {
        const newWidth = e.nativeEvent.layout.width;
        setWidth(newWidth)
      }}
      >
      <Animated.View style={{height, width: 200, borderRadius: height,backgroundColor: 'rgba(0,0,0,0.8)', transform: [{translateX: animatedValue}]}}>

      </Animated.View>

    </View>
  )
}

export default function ProgressBar(props) {

  return (
    <View style={styles.container}>
      <Progress step={props.progress} steps={props.totalDuration} height={7}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    position: 'absolute',
    right: 45
  },

});